import React from 'react';
import { motion } from 'framer-motion';

const variants = {
  visible: {
    marginBottom: 16,
    height: 'auto',
  },
  hidden: {
    marginBottom: 0,
    height: 0
  }
}

export default function FormError({ message, show }) {
  return (
    <motion.div 
      variants={ variants }
      animate={ show ? 'visible' : 'hidden' }
      initial='hidden'
      transition={{ duration: .2 }}
      className='contact-form-errors'>
      { message }
    </motion.div>
  )
}
